import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from "react-intl";
import { Form as FinalForm } from "react-final-form";
import * as validators from "../../../../../util/validators";
import { Button, FieldDateInput, FieldLocationAutocompleteInput, FieldSelect, Form } from "../../../../../components";
import { priceByGuestCount } from "../../../../../constants";
import IconCard from "../../../../../components/SavedCardDetails/IconCard/IconCard";
import classNames from "classnames";
import css from "./SearchFieldForm.module.css";
import { useConfiguration } from "../../../../../context/configurationContext";

const HOURS = Array(23).fill();
const getHours = (key) => {
    const updatedStartHours = [];
    HOURS.map((v, h) => {
        if (key == "start") {
            if (h > 9) updatedStartHours.push(`${h}:00`, `${h}:30`);
            else updatedStartHours.push(`0${h}:00`, `0${h}:30`);
        }
        else if (key == "end") {
            if (h > 9 && h == 23) updatedStartHours.push(`${h}:30`);
            else if (h > 9) updatedStartHours.push(`${h + 1}:00`, `${h + 1}:30`);
            else updatedStartHours.push(`0${h + 1}:00`, `0${h + 1}:30`);
        }
    })
    return updatedStartHours;
};
const ALL_START_HOURS = getHours("start");
const ALL_END_HOURS = getHours("end");

const SearchFieldsFormComponent = props => (
    <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={true}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                handleSubmit,
                form,
                formId,
                values,
                autoFocus,
                intl,
                invalid
            } = fieldRenderProps;

            const config = useConfiguration();
            const { listing: { listingFields } } = config || {};
            const capacityFields = listingFields.filter(item => item?.key == "capacity").map(item => item?.enumOptions).flat();

            // const [showErr, setShowErr] = useState(false);
            const [showContent, setShowContent] = useState(false);
            const [selectedItem, setSelectedItem] = useState(null);
            const [timeDropDown, setTimeDropDown] = useState(false);

            const hasTime = (values?.startHours != undefined && values?.endHours != undefined);

            const currentLocationMessage = intl.formatMessage({
                id: "SearchFieldForm.currentLocationMessage"
            })
            const contentRef = useRef();

            useEffect(() => {
                const selectedLocation = values?.location;
                const { search, selectedPlace, predictions } = selectedLocation || {};
                const { address, bounds, origin } = selectedPlace || {};
                if (search == "" && address == "") {
                    const newLocation = {
                        predictions: predictions,
                        search: currentLocationMessage,
                        selectedPlace: {
                            address: currentLocationMessage,
                            bounds,
                            origin
                        }
                    }
                    form.change("location", newLocation);
                }
                else {
                    form.change("location", selectedLocation);
                }
            }, [values?.location]);

            useEffect(() => {
                document.addEventListener('mousedown', handleClickOutside);
                return () => {
                    document.removeEventListener('mousedown', handleClickOutside);
                };
            }, []);

            useEffect(() => {
                form.change("startHours", "14:00");
                form.change("endHours", "15:00");
            }, [values?.bookingDate]);

            const classes = classNames(rootClassName || css.root, className);
            // location
            const addressRequiredMessage = intl.formatMessage({
                id: 'EditListingLocationForm.addressRequired',
            });
            const addressNotRecognizedMessage = intl.formatMessage({
                id: 'EditListingLocationForm.addressNotRecognized',
            });
            // search Label
            const searchLabel = intl.formatMessage({
                id: "SearchFieldsForm.searchButtonLabel"
            });
            // Disbaled values can be changed here.
            // const disabled = (values.location && values.bookingDate && !values.totalPersons && hasTime);
            const disabled = (!values.location);

            const handleButtonClick = () => {
                setShowContent(!showContent); // Toggle the state
                // setShowErr(true);
            };

            const handleClickOutside = (event) => {
                if (contentRef.current && !contentRef.current.contains(event.target)) {
                    setShowContent(false);
                }
            };
            const handleItemSelection = (item) => {
                form.change("totalPersons", item?.option);
                setSelectedItem(item);
                setShowContent(false);
            };

            const dateFieldMessage = intl.formatMessage({ id: "SearchFieldForm.requiredMessageDate" });
            const dateFieldNotValidMessage = intl.formatMessage({ id: "SearchFieldForm.dateFieldNotValidMessage" });
            const dateFieldValidators = validators.composeValidators(validators.required(dateFieldMessage), validators.bookingDateRequired(dateFieldNotValidMessage));
            const locationFieldValidators = validators.composeValidators(
                validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompletePlaceSelected(addressNotRecognizedMessage)
            );
            const startTimeLabel = intl.formatMessage({ id: "SearchFieldForm.startTimeText" });
            const endTimeLabel = intl.formatMessage({ id: "SearchFieldForm.endTimeText" });

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <div className={css.heroSearchBox}>
                        <div className={css.heroSlider}>
                            <div className={css.searchBarSlider}>

                                {/* Location Field */}
                                <div className={css.locationInput}>
                                    <FieldLocationAutocompleteInput
                                        className={css.inputBox}
                                        rootClassName={css.locationAddress}
                                        inputClassName={css.locationAutocompleteInput}
                                        iconClassName={css.locationAutocompleteInputIcon}
                                        predictionsClassName={css.predictionsRoot}
                                        validClassName={css.validLocation}
                                        autoFocus={autoFocus}
                                        name={formId ? `${formId}.location` : "location"}
                                        label={""}
                                        placeholder={intl.formatMessage({
                                            id: 'SearchFieldForm.addressPlaceholder',
                                        })}
                                        useDefaultPredictions={true}
                                        format={v => v}
                                        valueFromForm={values.location}
                                        validate={locationFieldValidators}
                                    />
                                </div>

                                {/* Date Filter */}
                                <div className={css.selectDate}>
                                    <FieldDateInput
                                        className={css.datePickerInput}
                                        id={"date"}
                                        name={"bookingDate"}
                                        placeholderText={intl.formatMessage({ id: "FieldDateInput.placeholder" })}
                                        label={""}
                                        useMobileMargins={false}
                                        format={(v) => v}
                                        // validate={dateFieldValidators}
                                    />
                                </div>

                                {/* Time Range Field */}
                                {/* <div>
                                    <div className={css.timeDropdownWrapper} onClick={() => setTimeDropDown(prev => !prev) }>
                                        <IconCard brand="clock"/>
                                        <span className={!hasTime ? css.timePlaceholder : css.showTimeHolder}>
                                            {!hasTime ? 'Seleccionar hora' : `${values?.startHours} - ${values?.endHours}`}
                                        </span>
                                    </div>
                                    {timeDropDown ?
                                        <div className={css.timeInputField}>
                                            <div>
                                                <FieldSelect id={"startHours"} name={"startHours"}>
                                                    <option disabled value={""}>{startTimeLabel}</option>
                                                    {ALL_START_HOURS.map(hr => <option key={hr} value={hr}>{hr}</option>)}
                                                </FieldSelect>
                                            </div>
                                            <div>
                                                <FieldSelect id={"endHours"} name={"endHours"} disabled={!(values?.startHours)}>
                                                    <option disabled value={""}>{endTimeLabel}</option>
                                                    {ALL_END_HOURS.filter(hr => hr > values?.startHours).map(hr => <option key={hr} value={hr}>{hr}</option>)}
                                                </FieldSelect>
                                            </div>
                                        </div>
                                    : null}
                                </div> */}

                                {/* Custom Drop-Down */}
                                <div className={css.customDropdown}>
                                    <div className={css.dropdownSelect} onClick={handleButtonClick}>
                                        <div className={css.labelBox}>
                                            {selectedItem ? selectedItem.label :
                                                <span className={css.defaultLabel}>
                                                    {intl.formatMessage({ id: "SearchFieldForm.totalPersonsPlaceholder" })}
                                                </span>}
                                        </div>
                                    </div>
                                    {showContent &&
                                        <ul className={css.guestCountUl} ref={contentRef}>
                                            {capacityFields.map((item, index) => (
                                                <li
                                                    key={index}
                                                    value={item.option}
                                                    onClick={() => handleItemSelection(item)}
                                                >
                                                    {/* {item.label + " " + intl.formatMessage({ id: "SearchFieldForm.itemLabelPersonas" })} */}
                                                    {item.label}
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                    {/* {(showErr && !values?.totalPersons) ? 
                                    <div className={css.customError}><FormattedMessage id={"SearchFieldForm.requiredError"} /></div> 
                                    : null} */}
                                </div>
                                <div className={css.submitButton}>
                                    {/* <Button type={"submit"} onClick={() => setShowErr(true)} disabled={disabled}>{searchLabel}</Button> */}
                                    <Button type={"submit"} disabled={disabled}>{searchLabel}</Button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </Form>
            )
        }}
    />
);

const SearchComponent = compose(injectIntl)(SearchFieldsFormComponent);
SearchComponent.displayName = "Search Component";
export default SearchComponent;